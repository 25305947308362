import axios from "axios";

// Ads
export const GET_ADS = "GET_ADS";

// Ads
export const getAds = () => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}api/ad/`)
      .then((res) => {
        dispatch({ type: GET_ADS, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};
