// ads

import { GET_ADS } from "../actions/ads.actions";

const initialState = {};

export default function adsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ADS:
      return action.payload;
    default:
      return state;
  }
}
