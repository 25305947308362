import React, { useState } from "react";

import { useTranslation } from "react-i18next";

const Conditions = () => {
  const { t } = useTranslation();

  const [TermsPopup, setTermsPopup] = useState(false);

  return (
    <div>
      <p onClick={() => setTermsPopup(true)} style={{ color: "blue" }}>
        {t("conditions générales.")}
      </p>
      {TermsPopup && (
        <div className="popup-profil-container">
          <div className="modal">
            <h3>"Terms and conditions of use"</h3>
            <span className="cross" onClick={() => setTermsPopup(false)}>
              &#10005;
            </span>
            <ul>
              <p style={{ textAlign: "justify" }}>
                StarCamer (URL address is https://starcamer.com/) is a social
                network that allows members to create unique personal profiles
                online in order to find and communicate with old and new
                friends. The service is operated by ALGORITHME (URL address is
                https://algorithme.cm/). By using the StarCamer Social Network
                you agree to be bound by these Terms of Use (this "Agreement"),
                whether or not you register as a member ("Member"). If you wish
                to become a Member, communicate with other Members and make use
                of the StarCamer services (the "Service"), please read this
                Agreement and indicate your acceptance by following the
                instructions in the Registration process.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                This Agreement sets out the legally binding terms for your use
                of the Social Network and your Membership in the Service.
                StarCamer may modify this Agreement from time to time and such
                modification shall be effective upon posting by StarCamer on the
                Social Network. You agree to be bound to any changes to this
                Agreement when you use the Service after any such modification
                is posted. This Agreement includes StarCamer's policy for
                acceptable use and content posted on the Social Network, your
                rights, obligations and restrictions regarding your use of the
                Social Network and the Service and StarCamer's Privacy Policy.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                Please choose carefully the information you post on StarCamer
                and that you provide to other Members. Any photographs posted by
                you may not contain nudity, violence, or offensive subject
                matter. Information provided by other StarCamer Members (for
                instance, in their Profile) may contain inaccurate,
                inappropriate or offensive material, products or services and
                StarCamer assumes no responsibility nor liability for this
                material.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                StarCamer reserves the right, in its sole discretion, to reject,
                refuse to post or remove any posting (including email) by you,
                or to restrict, suspend, or terminate your access to all or any
                part of the Social Network and/or Services at any time, for any
                or no reason, with or without prior notice, and without
                liability.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                By participating in any offline StarCamer event, you agree to
                release and hold StarCamer harmless from any and all losses,
                damages, rights, claims, and actions of any kind including,
                without limitation, personal injuries, death, and property
                damage, either directly or indirectly related to or arising from
                your participation in any such offline StarCamer event.
              </p>
              <br />
              <h4 style={{ textAlign: "justify" }}>Terms of Use :</h4>
              <br />
              <p style={{ textAlign: "justify" }}>1) Your Interactions.</p>
              <br />
              <p style={{ textAlign: "justify" }}>
                You are solely responsible for your interactions and
                communication with other Members. You understand that StarCamer
                does not in any way screen its Members, nor does StarCamer
                inquire into the backgrounds of its Members or attempt to verify
                the statements of its Members. StarCamer makes no
                representations or warranties as to the conduct of Members or
                their compatibility with any current or future Members. We do
                however recommend that if you choose to meet or exchange
                personal information with any member of StarCamer then you
                should take it upon yourself to do a background check on said
                person.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                In no event shall StarCamer be liable for any damages
                whatsoever, whether direct, indirect, general, special,
                compensatory, consequential, and/or incidental, arising out of
                or relating to the conduct of you or anyone else in connection
                with the use of the Service, including without limitation,
                bodily injury, emotional distress, and/or any other damages
                resulting from communications or meetings with other registered
                users of this Service or persons you meet through this Service.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>2) Eligibility.</p>
              <br />
              <p style={{ textAlign: "justify" }}>
                Membership in the Service where void is prohibited. By using the
                Social Network and the Service, you represent and warrant that
                all registration information you submit is truthful and accurate
                and that you agree to maintain the accuracy of such information.
                You further represent and warrant that you are 18 years of age
                or older and that your use of the StarCamer shall not violate
                any applicable law or regulation. Your profile may be deleted
                without warning, if it is found that you are misrepresenting
                your age. Your Membership is solely for your personal use, and
                you shall not authorize others to use your account, including
                your profile or email address. You are solely responsible for
                all content published or displayed through your account,
                including any email messages, and for your interactions with
                other members.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>3) Term/Fees.</p>
              <br />
              <p style={{ textAlign: "justify" }}>
                This Agreement shall remain in full force and effect while you
                use the Social Network, the Service, and/or are a Member. You
                may terminate your membership at any time. StarCamer may
                terminate your membership for any reason, effective upon sending
                notice to you at the email address you provide in your
                Membership application or other email address as you may
                subsequently provide to StarCamer. By using the Service and by
                becoming a Member, you acknowledge that StarCamer reserves the
                right to charge for the Service and has the right to terminate a
                Member's Membership if Member should breach this Agreement or
                fail to pay for the Service, as required by this Agreement.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                4) Non Commercial Use by Members.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                The Social Network is for the personal use of Members only and
                may not be used in connection with any commercial endeavors
                except those that are specifically endorsed or approved by the
                management of StarCamer. Illegal and/or unauthorized use of the
                Social Network, including collecting usernames and/or email
                addresses of Members by electronic or other means for the
                purpose of sending unsolicited email or unauthorized framing of
                or linking to the Social Network will be investigated.
                Commercial advertisements, affiliate links, and other forms of
                solicitation may be removed from member profiles without notice
                and may result in termination of membership privileges.
                Appropriate legal action will be taken by StarCamer for any
                illegal or unauthorized use of the Social Network.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                5) Proprietary Rights in Content on StarCamer.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                StarCamer owns and retains all proprietary rights in the Social
                Network and the Service. The Social Network contains copyrighted
                material, trademarks, and other proprietary information of
                StarCamer and its licensors. Except for that information which
                is in the public domain or for which you have been given written
                permission, you may not copy, modify, publish, transmit,
                distribute, perform, display, or sell any such proprietary
                information.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                6) Content Posted on the Social Network.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                a. You understand and agree that StarCamer may review and delete
                any content, messages, StarCamer Messenger messages, photos or
                profiles (collectively, "Content") that in the sole judgment of
                StarCamer violate this Agreement or which may be offensive,
                illegal or violate the rights, harm, or threaten the safety of
                any Member.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                b. You are solely responsible for the Content that you publish
                or display (hereinafter, "post") on the Service or any material
                or information that you transmit to other Members.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                c. By posting any Content to the public areas of the Social
                Network, you hereby grant to StarCamer the non-exclusive, fully
                paid, worldwide license to use, publicly perform and display
                such Content on the Social Network. This license will terminate
                at the time you remove such Content from the Social Network.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                d. The following is a partial list of the kind of Content that
                is illegal or prohibited on the Social Network. StarCamer
                reserves the right to investigate and take appropriate legal
                action in its sole discretion against anyone who violates this
                provision, including without limitation, removing the offending
                communication from the Service and terminating the membership of
                such violators. Prohibited Content includes Content that:
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                i. is patently offensive and promotes racism, bigotry, hatred or
                physical harm of any kind against any group or individual;
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                ii. harasses or advocates harassment of another person;
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                iii. involves the transmission of "junk mail", "chain letters,"
                or unsolicited mass mailing or "spamming";
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                iv. promotes information that you know is false or misleading or
                promotes illegal activities or conduct that is abusive,
                threatening, obscene, defamatory or libelous;
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                v. promotes an illegal or unauthorized copy of another person's
                copyrighted work, such as providing pirated computer programs or
                links to them, providing information to circumvent
                manufacture-installed copy-protect devices, or providing pirated
                music or links to pirated music files;
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                vi. contains restricted or password only access pages or hidden
                pages or images (those not linked to or from another accessible
                page);
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                vii. provides material that exploits people under the age of 18
                in a sexual or violent manner, or solicits personal information
                from anyone under 18;
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                viii. provides instructional information about illegal
                activities such as making or buying illegal weapons, violating
                someone's privacy, or providing or creating computer viruses;
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                ix. solicits passwords or personal identifying information for
                commercial or unlawful purposes from other users;
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                x. involves commercial activities and/or sales without our prior
                written consent such as contests, sweepstakes, barter,
                advertising, or pyramid schemes.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                e. You must use the Service in a manner consistent with any and
                all applicable laws and regulations.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                f. You may not engage in advertising to, or solicitation of, any
                Member to buy or sell any products or services through the
                Service. You may not transmit any chain letters or junk email to
                other Members. Although StarCamer cannot monitor the conduct of
                its Members off the Social Network, it is also a violation of
                these rules to use any information obtained from the Service in
                order to harass, abuse, or harm another person, or in order to
                contact, advertise to, solicit, or sell to any Member without
                their prior explicit consent. In order to protect our Members
                from such advertising or solicitation, StarCamer reserves the
                right to restrict the number of emails which a Member may send
                to other Members in any 24-hour period to a number which
                StarCamer deems appropriate in its sole discretion.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                g. You may not cover or obscure the banner advertisements on
                your personal profile page, or any StarCamer page via HTML/CSS
                or any other means.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                h. Any automated use of the system, such as using scripts to add
                friends, is prohibited.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                i. You may not attempt to impersonate another user or person who
                is not a member of StarCamer.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                j. You may not use the account, username, or password of another
                Member at any time nor may you disclose your password to any
                third party or permit any third party to access your account.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                k. You may not sell or otherwise transfer your profile.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>7) Copyright Policy.</p>
              <br />
              <p style={{ textAlign: "justify" }}>
                You may not post, distribute, or reproduce in any way any
                copyrighted material, trademarks, or other proprietary
                information without obtaining the prior written consent of the
                owner of such proprietary rights. It is the policy of StarCamer
                to terminate membership privileges of any member who repeatedly
                infringes copyright upon prompt notification to StarCamer by the
                copyright owner or the copyright owner's legal agent. Without
                limiting the foregoing, if you believe that your work has been
                copied and posted on the Service in a way that constitutes
                copyright infringement, please provide our Copyright Agent with
                the following information: an electronic or physical signature
                of the person authorized to act on behalf of the owner of the
                copyright interest; a description of the copyrighted work that
                you claim has been infringed; a description of where the
                material that you claim is infringing is located on the Social
                Network; your address, telephone number, and email address; a
                written statement by you that you have a good faith belief that
                the disputed use is not authorized by the copyright owner, its
                agent, or the law; a statement by you, made under penalty of
                perjury, that the above information in your notice is accurate
                and that you are the copyright owner or authorized to act on the
                copyright owner's behalf. StarCamer's Copyright Agent for notice
                of claims of copyright infringement can be reached via email
                address.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>8) Member Disputes.</p>
              <br />
              <p style={{ textAlign: "justify" }}>
                You are solely responsible for your interactions with other
                StarCamer Members. StarCamer reserves the right, but has no
                obligation, to monitor disputes between you and other Members.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>9) Disclaimers.</p>
              <br />
              <p style={{ textAlign: "justify" }}>
                You agree that online platforms can be dangerous!
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                StarCamer is not responsible for any incorrect or inaccurate
                content posted on the Social Network or in connection with the
                Service provided, whether caused by users of the Social Network,
                Members or by any of the equipment or programming associated
                with or utilized in the Service. StarCamer is not responsible
                for the conduct, whether online or offline, of any user of the
                Social Network or Member of the Service. StarCamer assumes no
                responsibility for any error, omission, interruption, deletion,
                defect, delay in operation or transmission, communications line
                failure, theft or destruction or unauthorized access to, or
                alteration of, any user or Member communication. StarCamer is
                not responsible for any problems or technical malfunction of any
                telephone network or lines, computer online systems, servers or
                providers, computer equipment, software, failure of any email or
                players due to technical problems or traffic congestion on the
                Internet or at any Social Network or combination thereof,
                including any injury or damage to users and/or Members or to any
                person's computer related to or resulting from participation or
                downloading materials in connection with the Social Network
                and/or in connection with the Service. Under no circumstances
                shall StarCamer be responsible for any loss or damage, including
                personal injury or death, resulting from use of the Social
                Network or the Service or from any Content posted on the Social
                Network or transmitted to Members, or any interactions between
                users of the Social Network, whether online or offline. The
                Social Network and the Service are provided "AS-IS" and
                StarCamer expressly disclaims any warranty of fitness for a
                particular purpose or non-infringement. StarCamer cannot
                guarantee and does not promise any specific results from use of
                the Social Network and/or the Service.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                10) Limitation on Liability.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                IN NO EVENT SHALL StarCamer BE LIABLE TO YOU OR ANY THIRD PARTY
                FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL
                OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT DAMAGES ARISING FROM
                YOUR USE OF THE SOCIAL NETWORK OR THE SERVICE, EVEN IF StarCamer
                HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
                StarCamer'S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND
                REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE
                LIMITED TO AMOUNT PAID, IF ANY, BY YOU TO StarCamer FOR THE
                SERVICE DURING THE TERM OF MEMBERSHIP.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>11) Disputes.</p>
              <br />
              <p style={{ textAlign: "justify" }}>
                If there is any dispute about or involving the Social Network
                and/or the Service, by using the Social Network, you agree that
                any dispute shall be governed by the laws of the area in which
                we are based without regard to conflict of law provisions and
                you agree to personal jurisdiction by and venue in the area in
                which we are based.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>12) Indemnity.</p>
              <br />
              <p style={{ textAlign: "justify" }}>
                You agree to indemnify and hold StarCamer, its subsidiaries,
                affiliates, officers, agents, and other partners and employees,
                harmless from any loss, liability, claim, or demand, including
                reasonable attorneys' fees, made by any third party due to or
                arising out of your use of the Service in violation of this
                Agreement and/or arising from a breach of this Agreement and/or
                any breach of your representations and warranties set forth
                above.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>13) Other.</p>
              <br />
              <p style={{ textAlign: "justify" }}>
                This Agreement is accepted upon your use of the Social Network
                and is further affirmed by you becoming a Member of the Service.
                This Agreement constitutes the entire agreement between you and
                StarCamer regarding the use of the Social Network and/or the
                Service. The failure of StarCamer to exercise or enforce any
                right or provision of this Agreement shall not operate as a
                waiver of such right or provision. The section titles in this
                Agreement are for convenience only and have no legal or
                contractual effect. Please contact us with any questions
                regarding this Agreement. StarCamer is a trademark of "owners
                business name".
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                I HAVE READ THIS AGREEMENT AND AGREE TO ALL OF THE PROVISIONS
                CONTAINED ABOVE.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                Last updated on January 1st, 2021.
              </p>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </ul>
          </div>
        </div>
      )}
      ;
    </div>
  );
};

export default Conditions;
