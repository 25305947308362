import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { dateParser, isEmpty } from "../Utils";

import { useTranslation } from "react-i18next";

const AdsCard = ({ ad }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const usersData = useSelector((state) => state.usersReducer);

  useEffect(() => {
    !isEmpty(usersData[0]) && setIsLoading(false);
  }, [usersData]);

  return (
    <li className="card-container" key={ad._id}>
      {isLoading ? (
        <i className="fas fa-spinner fa-spin"></i>
      ) : (
        <>
          <div className="card-left">
            <img src={ad.profilpicture} alt="poster-pic" />
          </div>
          <div className="card-right">
            <div className="card-header">
              <div className="pseudo">
                <h3>{ad.pseudo}</h3>
              </div>
              <span>{dateParser(ad.createdAt)}</span>
            </div>
            <p style={{ color: "#ff7b77" }}>{t("Sponsorisé")}</p>
            <br />
            <p>{ad.message}</p>
            {ad.postpicture && (
              <img src={ad.postpicture} alt="card-pic" className="card-pic" />
            )}
            {ad.video && (
              <iframe
                width="500"
                height="300"
                src={ad.video}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={ad._id}
              ></iframe>
            )}
          </div>
        </>
      )}
    </li>
  );
};

export default AdsCard;
