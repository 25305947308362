import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/index.scss";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { getUsers } from "./actions/users.actions";
import { getPosts } from "./actions/post.actions";
import { getAds } from "./actions/ads.actions";

import "./i18next";

// dev tools
// import { composeWithDevTools } from "redux-devtools-extension";

const store = createStore(rootReducer, applyMiddleware(thunk));

store.dispatch(getUsers());
store.dispatch(getPosts()); // Trending
store.dispatch(getAds()); // Ads

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<div>Loading ~~</div>}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
