import React from "react";
import { NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";

const LeftNav = () => {
  const { t } = useTranslation();

  return (
    <div className="left-nav-container">
      <div className="icons">
        <div className="icons-bis">
          <NavLink to="/" exact activeClassName="active-left-nav">
            <img
              src="./img/icons/homescreen.svg"
              alt="starcamer-home"
              title={t("Accueil")}
            />
          </NavLink>
          <br />
          <NavLink to="/sponsored" exact activeClassName="active-left-nav">
            <img
              src="./img/icons/sponsored.svg"
              alt="starcamer-sponsored"
              title={t("Sponsorisé")}
            />
          </NavLink>
          <br />
          <NavLink to="/trending" exact activeClassName="active-left-nav">
            <img
              src="./img/icons/star.svg"
              alt="starcamer-trending"
              title={t("Stars des stars")}
            />
          </NavLink>
          <br />
          <NavLink to="/profil" exact activeClassName="active-left-nav">
            <img
              src="./img/icons/profil.svg"
              alt="starcamer-user"
              title={t("Profil")}
            />
          </NavLink>
          <br />
          <ul>
            <a
              href="https://wa.me/237680404550?text=Bonjour%20StarCamer.%20Besoin%20de%20faire%20de%20la%20publicité.%20Need%20to%20advertise.%20"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="./img/icons/ads.svg"
                alt="starcamer-advertisement"
                title={t("Publicité")}
              />
            </a>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LeftNav;
