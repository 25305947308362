// Ads
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAds } from "../actions/ads.actions";
import { isEmpty } from "./Utils";
import { NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";

const Ads = () => {
  const { t } = useTranslation();

  // Store (reducer) de Ads
  const adList = useSelector((state) => state.adsReducer);
  const ads = useSelector((state) => state.adsReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(ads[0])) {
      const adsArr = Object.keys(ads).map((i) => ads[i]);
      let sortedAdsArray = adsArr;
      dispatch(getAds(sortedAdsArray));
    }
  }, [ads, dispatch]);

  return (
    <div className="trending-container">
      <h4>{t("Sponsorisé")}</h4>
      <NavLink exact to="/sponsored">
        <ul>
          {adList.length &&
            adList.map((ad) => {
              return (
                <li key={ad._id}>
                  <div>
                    {ad.postpicture && (
                      <img src={ad.postpicture} alt="post-pic" />
                    )}
                    {ad.video && (
                      <iframe
                        src={ad.video}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title={ad._id}
                      ></iframe>
                    )}
                    {isEmpty(ad.postpicture) &&
                      isEmpty(ad.video) &&
                      (<img src={ad.profilpicture} alt="profil-pic" />).join(
                        ""
                      )}
                  </div>
                  <div className="trend-content">
                    <p>{ad.message}</p>
                    <span>{t("Voir plus")}...</span>
                  </div>
                </li>
              );
            })}
        </ul>
      </NavLink>
    </div>
  );
};

export default Ads;
