import React, { useState } from "react";
import axios from "axios";
import SignInForm from "./SignInForm";
import Conditions from "../../pages/Conditions";

import { useTranslation } from "react-i18next";

// Notification
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

const SignUpForm = () => {
  const { t } = useTranslation();

  const [formSubmit, setFormSubmit] = useState(false);
  const [pseudo, setPseudo] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [controlPassword, setControlPassword] = useState("");

  const notify = () => toast("The world of Stars is one step away from you.");

  const handleRegister = async (e) => {
    e.preventDefault();
    const terms = document.getElementById("terms");
    const pseudoError = document.querySelector(".pseudo.error");
    const emailError = document.querySelector(".email.error");
    const phoneError = document.querySelector(".phone.error");
    const passwordError = document.querySelector(".password.error");
    const passwordConfirmError = document.querySelector(
      ".password-confirm.error"
    );
    const termsError = document.querySelector(".terms.error");

    passwordConfirmError.innerHTML = "";
    termsError.innerHTML = "";

    if (password !== controlPassword || !terms.checked) {
      if (password !== controlPassword)
        passwordConfirmError.innerHTML =
          "Passwords are not the same. Please check and try again.";

      if (!terms.checked)
        termsError.innerHTML = "Please validate the general conditions.";
    } else {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}api/user/register`,
        data: {
          pseudo,
          email,
          phone,
          password,
        },
      })
        .then((res) => {
          if (res.data.errors) {
            pseudoError.innerHTML = res.data.errors.pseudo;
            emailError.innerHTML = res.data.errors.email;
            phoneError.innerHTML = res.data.errors.phone;
            passwordError.innerHTML = res.data.errors.password;
          } else {
            setFormSubmit(true);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      {formSubmit ? (
        <>
          <SignInForm />
          <span></span>
          <h4 className="success">
            {t("Enregistrement réussi. Tu peux te connecter maintenant.")}
          </h4>
        </>
      ) : (
        <form action="" onSubmit={handleRegister} id="sign-up-form">
          <label htmlFor="pseudo">Pseudo</label>
          <br />
          <input
            type="text"
            placeholder="ex.: Aboubakar"
            name="pseudo"
            id="pseudo"
            onChange={(e) => setPseudo(e.target.value)}
            value={pseudo}
          />
          <div className="pseudo error"></div>
          <br />
          <label htmlFor="email">Email</label>
          <br />
          <input
            type="text"
            placeholder="ex.: abdou@gmail.com"
            name="email"
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <div className="email error"></div>
          <br />
          <label htmlFor="phone">{t("Téléphone")}</label>
          <br />
          <input
            type="text"
            placeholder="ex.: 666777999"
            name="phone"
            id="phone"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
          />
          <div className="phone error"></div>
          <br />
          <label htmlFor="password">{t("Mot de passe")}</label>
          <br />
          <input
            type="password"
            placeholder="ex.: Pass12&@"
            name="password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <div className="password error"></div>
          <br />
          <label htmlFor="password-conf">
            {t("Confirmer le mot de passe")}
          </label>
          <br />
          <input
            type="password"
            placeholder="ex.: Pass12&@"
            name="password"
            id="password-conf"
            onChange={(e) => setControlPassword(e.target.value)}
            value={controlPassword}
          />
          <div className="password-confirm error"></div>
          <br />
          <input type="checkbox" id="terms" />
          <label htmlFor="terms">
            J'accepte les <Conditions />
          </label>
          <div className="terms error"></div>
          <input type="submit" value={t("S'inscrire")} onClick={notify} />
        </form>
      )}
    </>
  );
};

export default SignUpForm;
